import React from 'react';
import { PictureParagraph, PrimaryText } from '../components';
import { Box } from '@mui/system';
import Container from 'components/Container';
import { TabTitle } from './Styles';
import FirstImage from 'images/Properties/market/01.svg';
import SecondImage from 'images/Properties/market/02.svg';
import ThirdImage from 'images/Properties/market/03.svg';
import Disclaimer from '../components/Disclaimer';
import {
  useLocalise,
  useRemapper,
  useStringToArrayRows,
} from 'hooks/use-localise';
import { PrimaryTextProps } from '../components/PrimaryText/PrimaryText';

const PrimaryTextContent = {
  aboutMarket: {
    id: 1,
    title: 'aboutMarket',
    anchor: 'market',
    content: ['aboutMarket'],
  },
  propertyMarket: {
    id: 2,
    title: 'propertyMarket',
    anchor: '',
    content: ['propertyMarket'],
  },
  rentalPrices: {
    id: 3,
    title: 'rentalPrices',
    anchor: '',
    content: ['rentalPrices'],
  },
};

const pictureParagraphContent = {
  growthInVejle: {
    id: 1,
    title: 'growthInVejle',
    altTxt: 'growthInVejle',
    alt: 'growthInVejle',
    imageSrc: FirstImage,
  },
  priceIndex: {
    id: 1,
    title: 'priceIndex',
    altTxt: 'priceIndex',
    alt: 'priceIndex',
    imageSrc: SecondImage,
  },
  averageRentalPrice: {
    id: 1,
    title: 'averageRentalPrice',
    altTxt: 'averageRentalPrice',
    alt: 'averageRentalPrice',
    imageSrc: ThirdImage,
  },
};

const MarketTab = (): JSX.Element => {
  // Establishing localise with viewname and sectionName
  const { localise } = useLocalise('Properties', 'Market');
  const remapper = useRemapper(localise);

  const localisedAboutMarket = useStringToArrayRows(
    localise,
    PrimaryTextContent['aboutMarket'],
    { rowName: 'content' },
  ) as PrimaryTextProps;

  console.log('localisedAboutMarket', localisedAboutMarket);

  const localisedPropertyMarket = useStringToArrayRows(
    localise,
    PrimaryTextContent['propertyMarket'],
    { rowName: 'content' },
  ) as PrimaryTextProps;

  const localisedRentalPrices = useStringToArrayRows(
    localise,
    PrimaryTextContent['rentalPrices'],
    { rowName: 'content' },
  ) as PrimaryTextProps;

  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'column', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <TabTitle>{localise('title')}</TabTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          gap: '52px',
          paddingTop: '58px',
        }}
      >
        <Box>
          <PrimaryText content={localisedAboutMarket} />
          <PictureParagraph
            content={remapper(pictureParagraphContent['growthInVejle'], [
              'title',
              'altTxt',
              'alt',
            ])}
          />
        </Box>
        <Box
          sx={{
            marginTop: { md: '-48px', sm: 'initial', xs: 'initial' },
          }}
        >
          <PrimaryText content={localisedPropertyMarket} />
          <PictureParagraph
            content={remapper(pictureParagraphContent['priceIndex'], [
              'title',
              'altTxt',
              'alt',
            ])}
          />
          <PrimaryText
            content={localisedRentalPrices}
            sx={{ paddingTop: '84px' }}
          />
          <PictureParagraph
            content={remapper(pictureParagraphContent['averageRentalPrice'], [
              'title',
              'altTxt',
              'alt',
            ])}
          />
        </Box>
      </Box>
      <Disclaimer />
    </Container>
  );
};

export default MarketTab;
